const lightBgRegexList = [
  /\/continent-sea/,
  /\/notre-engagement-responsable$/,
  /\/qui-sommes-nous/,
  /\/voyage-/,
  /\/quand-partir-/,
  /\/inspiration-voyage$/
];

const listExceptInspiration = [
  /\/voyage-au-soleil/,
  /\/voyage-iles/,
  /\/voyage-bien-etre-detente-spa/,
  /\/voyage-culinaire-sejour-oenologique/,
  /\/voyage-culturel/,
  /\/voyage-de-luxe/,
  /\/voyage-de-noces/,
  /\/voyage-en-familles/,
  /\/voyage-en-train/,
  /\/voyage-nature-grands-espaces/,
  /\/voyage-sur-mesure/,
  /\/voyage-seul/
];

export default function isLightBackgroundLink(url: string): boolean {
  if (
    lightBgRegexList.some(regexList => regexList.test(url)) &&
    !listExceptInspiration.some(exceptInspiration => exceptInspiration.test(url))
  ) {
    return true;
  }

  return false;
}
